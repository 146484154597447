<template>
  <div :class="classProp">
    <h6 v-if="!isHiddenLabel">Chọn Chợ nội bộ</h6>
    <v-select
      placeholder="Chọn Chợ nội bộ"
      v-model="supplierInfo"
      label="name"
      :clearable="false"
      :options="marketList"
      :disabled="isDisabled"
      @input="handleChangeInventory"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <feather-icon icon="ChevronDownIcon" size="20" />
        </span>
      </template>
    </v-select>
  </div>
</template>
<script>
import appUtils from "@/utils/appUtils";
import vSelect from "vue-select";
import { mapState } from "vuex";

export default {
  name: "SelectPharma",
  props: { isDisabled: Boolean, classProp: String, isHiddenLabel: Boolean },
  components: { vSelect },
  data() {
    return {
      supplierInfo: {}
    };
  },
  computed: {
    ...mapState({
      userLogin: state => state.authenticate.userLogin,
      marketList: state => state.authenticate.userLogin?.ecom_suppliers || []
    })
  },
  watch: {
    userLogin: {
      deep: true,
      handler() {
        this.supplierInfo = this.userLogin?.supplierInfo || {};
      }
    }
  },
  created() {
    if (this.userLogin?.supplierInfo) {
      this.supplierInfo = this.userLogin?.supplierInfo || {};
    }
  },
  methods: {
    handleChangeInventory(supplier) {
      const newUserLogin = {
        ...this.userLogin,
        supplierInfo: supplier
      };

      appUtils.setLocalUser(JSON.stringify(newUserLogin));

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  },
  destroyed() {
    this.pharmaInfo = {};
  }
};
</script>
  
  <style lang="scss" scoped>
</style>