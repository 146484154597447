<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in navItems"
      :key="`${item.header || item.title}-${item.route}`"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import appUtils from '@/utils/appUtils'
import { ROLES_SYSTEM } from '@/utils/constant'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      navItems: []
    }
  },
  watch: {
    items() {
      this.handleMapData()
    }
  },
  beforeMount() {
    this.handleMapData()
  },
  methods: {
    handleMapData() {
      this.navItems = [...(this.items || [])]
      // const userData = appUtils.getLocalUser()

      // // TODO: update when implement api
      // // const isSupplierProduct = false;

      // // Supplier Product

      // console.log(this.$role)
      // if (this.$role === ROLES_SYSTEM.SUPPLIER_PRODUCT) {
      //   const newItems = this.items?.filter(
      //     item =>
      //       item?.role?.includes(ROLES_SYSTEM.ALL) ||
      //       item?.role?.includes(ROLES_SYSTEM.SUPPLIER_PRODUCT)
      //   )
      //   this.navItems = [...newItems]
      //   // this.$role = ROLES_SYSTEM.SUPPLIER_PRODUCT;
      //   return
      // }

      // // Supplier/Processor Service
      // // const isSupplier = userData?.suppliers?.length;
      // // const isProcessor = userData?.processors?.length;
      // if (this.$role === ROLES_SYSTEM.PROCESSOR) {
      //   const newItems = this.items?.filter(
      //     item =>
      //       item?.role?.includes(ROLES_SYSTEM.ALL) ||
      //       item?.role?.includes(ROLES_SYSTEM.PROCESSOR)
      //   )
      //   this.navItems = [...newItems]

      //   // this.$role = ROLES_SYSTEM.PROCESSOR;
      // } else if (this.$role === ROLES_SYSTEM.SUPPLIER) {
      //   const newItems = this.items?.filter(
      //     item =>
      //       item?.role?.includes(ROLES_SYSTEM.ALL) ||
      //       item?.role?.includes(ROLES_SYSTEM.SUPPLIER) ||
      //       item?.role?.includes(ROLES_SYSTEM.SUPPLIER_PRODUCT)
      //   )
      //   this.navItems = [...newItems]
      //   // this.$role = ROLES_SYSTEM.SUPPLIER;
      // } else if (this.$role === ROLES_SYSTEM.ALL) {
      //   const newItems = this.items?.filter(item => {
      //     console.log('🚀 ~ handleMapData ~ item:', item)
      //     console.log(
      //       'item?.role?.includes(ROLES_SYSTEM.ALL) === ROLES_SYSTEM.ALL',
      //       item?.role?.includes(ROLES_SYSTEM.ALL)
      //     )
      //     return item?.role?.includes(ROLES_SYSTEM.ALL)
      //   })
      //   this.navItems = [...newItems]
      //   // this.$role = ROLES_SYSTEM.ALL;
      // }
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent
    }
  }
}
</script>
