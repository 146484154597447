<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <dark-Toggler class="d-none d-lg-block mr-1" /> -->
      <Locale />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <Notification />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userLogin.name }}
            </p>
            <!-- <span class="user-status">{{ userLogin.id }}</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userLogin.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t('Thông tin nhà cung cấp') }}</span>
        </b-dropdown-item>-->

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item>-->

        <!-- <b-dropdown-divider /> -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("lbl_logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import Locale from "@/@core/layouts/components/app-navbar/components/Locale.vue";
import store from "@/store/index";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import {
  BAvatar,
  BDropdownDivider,
  BDropdownItem,
  BFormSelect,
  BFormSelectOption,
  BLink,
  BNavItemDropdown,
  BNavbarNav,
} from "bootstrap-vue";
import { deleteToken, getMessaging } from "firebase/messaging";
import { mapGetters } from "vuex";
import Notification from "./Notification.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
    Notification,
    BFormSelect,
    BFormSelectOption,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userLogin: "authenticate/userLogin",
    }),
  },
  methods: {
    async logout() {
      await store.dispatch("authenticate/logout", () => {
        this.$router.push({ name: "login" });
        deleteToken(getMessaging());
      });
    },
  },
};
</script>

<style lang="scss">
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: auto;
  min-width: 12rem;
}
</style>
