<template>
  <div id="notification_tab">
    <div v-if="notis && notis.length">
      <div class="card border-0 my-4">
        <div
          class="card-body card-body-scrollable card-body-scrollable-shadow p-0"
          style="background: #F4F6FA;"
        >
          <div v-if="!loading_content" class="notification-tab">
            <div
              v-for="(noti,index) in notis"
              :key="index"
              style="margin: 14px 16px 11px 14px; border-radius: 6px;"
            >
              <div :set="bodyData= getBodyData(noti)">
                <div
                  class="card-noti"
                  @click="bodyData.event_type===NOTIFICATION_STATUS_TYPE.SUPPLIER_REJECT_LAB?()=>{}:readNotification(noti)"
                >
                  <div class="d-flex pb-1 border-bottom justify-content-between">
                    <div class="d-flex align-items-center relative">
                      <div class="d-flex align-items-center robo-16-500 txt-pri">
                        <span style="max-width:250px;" :class="bodyData.color">
                          <strong>{{bodyData.title}}</strong>
                        </span>
                      </div>
                      <div
                        v-if="noti.is_read===1"
                        class="bullet bullet-sm bullet-danger absolute top-0"
                        style="margin-left: 4px; margin-top:4px;right:-14px"
                      />
                    </div>
                    <div
                      class="ml-2 d-flex align-items-center justify-content-end robo-14-400 text-black-50"
                    >{{formatDateAgo(noti.changed_at)}}</div>
                  </div>
                  <div style="padding: 10px 0 10px 0;">
                    <div v-html="bodyData.message"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading_content" class="divide-y-4 notification-tab">
            <ul class="list-group list-group-flush">
              <li v-for="i in 10" class="list-group-item" style="background-color:unset;" :key="i">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <div class="col-9">
                    <b-skeleton></b-skeleton>
                    <b-skeleton></b-skeleton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NOTIFICATION_STATUS_TYPE } from "@/utils/constant";
import appUtils from "../../utils/appUtils";
import i18n from "@/libs/i18n";
export default {
  name: "NotificationListing",
  props: ["notis", "loading"],
  data() {
    return {
      appUtils,
      loading_content: false,
      NOTIFICATION_STATUS_TYPE
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    async readNotification(noti) {
      const formatData = JSON.parse(noti.body);
      if (noti.is_read === 1) {
        const params = {
          ids: [noti.id]
        };

        this.$store.dispatch("notification/updateReadNotifications", {
          params,
          callback: () => {
            this.$emit("getNotis");
          }
        });
      }

      if (!formatData?.supplier_order_id && !formatData?.order_processor_id)
        return;

      const userData = appUtils.getLocalUser();
      const isProcessor = userData?.processors?.length;
      if (isProcessor) {
        this.$router.replace({
          name: "detail-order-processor",
          params: { id: formatData.order_processor_id }
        });
      } else {
        this.$router.replace({
          name: "orderDetail",
          params: { id: formatData.supplier_order_id }
        });
      }
      this.closeNoti();
    },

    formatDateAgo(date) {
      // const timestamp = Math.floor(date / 1000);

      return window
        .moment(date)
        .locale(i18n.locale || "vi")
        .fromNow();
    },

    closeNoti() {
      this.$emit("onCloseNoti", false);
    },
    getBodyData(notiInfo) {
      if (!notiInfo?.body) return {};
      const userData = appUtils.getLocalUser();
      const isProcessor = userData?.processors?.length;
      const formatData = JSON.parse(notiInfo.body);
      let title = "";
      let message = "";
      let color = "secondary";
      const orderCode = `<strong>#${formatData.order_code}</strong>`;

      switch (formatData.event_type) {
        case NOTIFICATION_STATUS_TYPE.CREATE:
          title = this.$t(isProcessor ? "lbl_new_order" : "lbl_new_order");
          message = this.$t("fm_new_order", {
            id: orderCode
          });
          color = "text-success";
          break;
        case NOTIFICATION_STATUS_TYPE.SUPPLIER_UPDATE:
        case NOTIFICATION_STATUS_TYPE.PROCESSOR_UPDATE:
          title = this.$t(
            isProcessor ? "lbl_order_changed" : "lbl_order_changed"
          );
          message = this.$t(
            isProcessor ? "fm_order_changed" : "fm_order_changed",
            {
              id: orderCode
            }
          );
          color = "text-primary";
          break;
        case NOTIFICATION_STATUS_TYPE.SUPPLIER_REJECT_LAB:
          title = this.$t(
            isProcessor ? "lbl_order_canceled" : "lbl_order_canceled"
          );
          message = this.$t(
            isProcessor ? "fm_order_canceled" : "fm_order_canceled",
            {
              id: orderCode
            }
          );
          color = "text-danger";
          break;
        case NOTIFICATION_STATUS_TYPE.NEW_COMMENT:
          title = this.$t("lbl_order_has_new_comments");
          message = this.$t("lbl_order_$_ordercode__has_new_comments", {orderCode: orderCode});
          color = "text-warning";
          break;

        default:
          title = this.$t(
            isProcessor ? "lbl_new_notification" : "lbl_new_notification "
          );
          message = this.$t(
            isProcessor
              ? `lbl_you_have_a_new_notification`
              : `lbl_you_have_a_new_notification`
          );
          break;
      }

      return {
        ...formatData,
        title,
        message,
        color,
        supplier_order_id: formatData?.supplier_order_id
      };
    }
  }
};
</script>
<style scoped>
.noti-default {
  height: 28px;
  width: 28px;
  background-size: 28px;
}

.card-noti {
  background: #ffffff;
  padding: 4px 7px;
  border-radius: 6px;
  cursor: pointer;
}
</style>