<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ handleGetTitle() }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
                >{{ handleGetBreadCrumbLabel(item) }}</b-breadcrumb-item
              >
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <!-- <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >

        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>

        <b-dropdown-item :to="{ name: 'apps-todo' }">
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Todo</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-chat' }">
          <feather-icon
            icon="MessageSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Chat</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-email' }">
          <feather-icon
            icon="MailIcon"
            size="16"
          />
          <span class="align-middle ml-50">Email</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-calendar' }">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <span class="align-middle ml-50">Calendar</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>-->
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapState } from "vuex";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  // watch: {
  //   $route() {
  //     this.handleGetTitle();
  //   }
  // },
  computed: {
    ...mapState({
      is_test_provider: (state) => state.authenticate.is_test_provider,
    }),
  },
  created() {
    // this.handleGetTitle();
  },
  methods: {
    handleGetTitle() {
      let title = "";
      switch (this.$route.name) {
        case "orders":
          title = this.$t(
            this.$role === 1
              ? "breadscrumb.lbl_required_test_manager"
              : "breadscrumb.lbl_orders"
          );
          break;
        case "orderDetail":
          title = this.$t(
            this.$role === 1
              ? "breadscrumb.lbl_test_detail"
              : "breadscrumb.lbl_orders_detail"
          );
          break;
        case "inventory-service":
          title = this.$t(
            this.$role === 1
              ? "breadscrumb.lbl_test_inventory"
              : "breadscrumb.lbl_inventory"
          );
          break;
        case "services":
          title = this.$t(
            this.$role === 1
              ? "breadscrumb.lbl_test_list"
              : "breadscrumb.lbl_product___services"
          );
          break;
        case "configs":
          title = this.$t(
            this.$role === 1
              ? "breadscrumb.lbl_system_settings"
              : "breadscrumb.lbl_config_settings"
          );
          break;
        case "config-definition":
          title = this.$t(
            this.$role === 1
              ? "breadscrumb.lbl_delivery_definition_configs"
              : "breadscrumb.lbl_delivery_definition_configs"
          );
          break;
        case "config-order-form":
          title = this.$t(
            this.$role === 1
              ? "breadscrumb.lbl_conditions_of_service"
              : "breadscrumb.lbl_conditions_of_service"
          );
          break;
        case "config-delivery-status":
          title = this.$t(
            this.$role === 1
              ? "breadscrumb.lbl_delivery_status_configs"
              : "breadscrumb.lbl_delivery_status_configs"
          );
          break;

        default:
          title = this.$t(this.$route.meta.pageTitle);
          break;
      }
      return title;
    },
    handleGetBreadCrumbLabel(item) {
      switch (item.to) {
        case "/orders":
          return this.$t("breadscrumb.lbl_required_test_manager");
        case "/services":
          return this.$t("breadscrumb.lbl_test_list");
        default:
          return this.$t(item.text);
      }
    },
  },
};
</script>
