<template>
  <div class="mr-1">
    <div @click="onShowNoti(true)" :class="notificationStore.hasNewMessage?'cs-bell':''">
      <div class="relative">
        <feather-icon icon="BellIcon" size="24" />
        <span v-if="notificationStore.totalNotificationUnread" class="cs-amount">{{countLabel }}</span>
      </div>
    </div>
    <div v-show="isShowing" class="notification-open p-1">
      <div class="notification-side m-0 shadow" id="notification">
        <div class="noti-close" @click="onShowNoti(false)"></div>

        <div>
          <NotificationListing
            v-if="notificationStore.notifications && notificationStore.notifications.length"
            @onCloseNoti="onShowNoti"
            @getNotis="handleGetNotis"
            :notis="notificationStore.notifications"
            :loading="notificationStore.loading_noti"
          />
          <div
            v-if="!notificationStore.loading_noti && (!notificationStore.notifications || !notificationStore.notifications.length)"
          >
            <div class="card mt-4">
              <div
                class="card-body card-body-scrollable card-body-scrollable-shadow p-1 flex items-center justify-center"
                style="height: '4.5rem'"
              >
                <div class="divide-y-4 notification-tab">
                  <div>
                    <a class="notification-link" href="#">
                      <div class="row p-2">
                        <div class="col-auto">
                          <span>
                            <feather-icon icon="BellIcon" size="20" />
                          </span>
                        </div>
                        <div class="col">
                          <div class="text-truncate">
                            <strong class="txt-pri">{{$t('notification.lbl_empty_notifications')}}</strong>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-4" v-if="notificationStore.loading_noti">
            <ul class="list-group list-group-flush">
              <li v-for="i in 10" class="list-group-item" style="background-color:unset;" :key="i">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <div class="col-9">
                    <b-skeleton></b-skeleton>
                    <b-skeleton></b-skeleton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div @click="onShowNoti(false)" class="dropdown-backdrop bg-drop cs-backdrop"></div>
    </div>
  </div>
</template>

<script>
import appUtils from "@/utils/appUtils";
import NotificationListing from "./NotificationListing.vue";
import { mapState } from "vuex";
import { NOTIFICATION_TYPE } from "@/utils/constant";
// import {debounce} from "lodash"

export default {
  name: "Notification",
  components: { NotificationListing },
  data() {
    return {
      isShowing: false
    };
  },
  watch: {
    isShowing: {
      handler: function() {
        let self = this;
        const listNoti = document.querySelector("#notification");
        if (listNoti) {
          listNoti.addEventListener("scroll", e => {
            if (
              listNoti.scrollTop + listNoti.clientHeight >=
              listNoti.scrollHeight
            ) {
              const currentPage = this.notificationStore.notificationMeta
                .page_num;
              const totalPage = this.notificationStore.notificationMeta
                .total_page;
              const nextPage = currentPage + 1;

              if (
                nextPage <= totalPage &&
                !this.notificationStore.loading_noti
              ) {
                this.handleGetNotis({ page_num: nextPage }, true);
              }
            }
          });
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      notificationStore: state => state.notification
    }),
    countLabel() {
      return this.notificationStore.totalNotificationUnread < 100
        ? this.notificationStore.totalNotificationUnread
        : `99+`;
    }
  },
  beforeMount() {
    this.handleGetNotis();
  },
  methods: {
    onShowNoti(show) {
      if (show) {
        this.$store.commit("notification/setHasNewMessage", { isNew: false });
        this.handleGetNotis({}, false);
      }

      this.isShowing = show;
    },
    handleGetNotis(metaData, isPush) {
      try {
        this.loading_noti = true;
        const userData = appUtils.getLocalUser();
        const isProcessor = userData?.processors?.length;

        const params = {
          user_id: userData.id,
          type_notification: isProcessor
            ? NOTIFICATION_TYPE.PROCESSOR
            : NOTIFICATION_TYPE.SUPPLIER,
          sort_by: "changed_at",
          order: "desc",
          page_num: 1,
          page_size: 10,
          ...metaData
        };
        this.$store.dispatch("notification/getNotifications", {
          params,
          isPush
        });
      } catch (error) {
      } finally {
        this.loading_noti = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-open {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  z-index: 1039;
}

.notification-side {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 400px;
  right: 0;
  background-color: #f4f6fa;
  z-index: 1032;
}
.pl-250px {
  margin-left: 250px;
}
.pl-56px {
  margin-left: 56px;
}

.cs-bell {
  padding: 6px;
  cursor: pointer;
}

.cs-backdrop {
  cursor: pointer;
  width: 100vw;
  height: 100vh;
}

.noti-close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  background-size: 20px;
  background-image: url("../../assets/images/icons/icon_close.svg");
  cursor: pointer;
}
.cs-amount {
  position: absolute;
  top: -10px;
  right: -14px;
  color: white;
  background-color: red;
  border-radius: 50%;
  width: fit-content;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 12px;
  font-weight: 500;
}

.cs-bell {
  display: block;
  color: #9e9e9e;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
  width: 24px;
  height: 24px;
  padding: 0 !important;

  @keyframes ring {
    0% {
      transform: rotate(0);
    }
    1% {
      transform: rotate(30deg);
    }
    3% {
      transform: rotate(-28deg);
    }
    5% {
      transform: rotate(34deg);
    }
    7% {
      transform: rotate(-32deg);
    }
    9% {
      transform: rotate(30deg);
    }
    11% {
      transform: rotate(-28deg);
    }
    13% {
      transform: rotate(26deg);
    }
    15% {
      transform: rotate(-24deg);
    }
    17% {
      transform: rotate(22deg);
    }
    19% {
      transform: rotate(-20deg);
    }
    21% {
      transform: rotate(18deg);
    }
    23% {
      transform: rotate(-16deg);
    }
    25% {
      transform: rotate(14deg);
    }
    27% {
      transform: rotate(-12deg);
    }
    29% {
      transform: rotate(10deg);
    }
    31% {
      transform: rotate(-8deg);
    }
    33% {
      transform: rotate(6deg);
    }
    35% {
      transform: rotate(-4deg);
    }
    37% {
      transform: rotate(2deg);
    }
    39% {
      transform: rotate(-1deg);
    }
    41% {
      transform: rotate(1deg);
    }

    43% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(0);
    }
  }
}
</style>
