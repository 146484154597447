<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div
        class="content-body"
        :class="$route.name === 'reports' && 'scroll-bar'"
      >
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "~@core/scss/base/bootstrap-extended/variables";
@import "bootstrap/scss/variables";
@import "~@core/scss/base/components/variables-dark";
@import "~@core/scss/base/components/variables";

.scroll-bar {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: #949393;
    border-radius: 8px;
    .dark-layout & {
      background-color: $nav-component-border-color !important;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: rgba(241, 241, 241, 0.4);
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  // }

  ::-webkit-scrollbar-corner {
    display: none;
  }
}
</style>
