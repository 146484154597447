<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language hidden-dot"
    right
  >
    <template #button-content>
      <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
      <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="handleChangeLang(localeObj)"
    >
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ $t(localeObj.name) }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import store from "@/store";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg
  },
  computed: {
    currentLocale() {
      return (
        this.locales.find(l => l.locale === this.$i18n.locale) ||
        this.locales[0]
      );
    }
  },
  created() {
    const langLocal = window.localStorage.getItem("lang");
    if (langLocal === "undefined") {
      this.$i18n.locale = "vi";
      return;
    }

    if (langLocal) {
      this.$i18n.locale = langLocal;
    } else {
      if (navigator.language !== "vi-VN") this.handleChangeLang("en");
    }
  },
  methods: {
    handleChangeLang(localeObj) {
      this.$i18n.locale = localeObj.locale;
      window.localStorage.setItem("lang", localeObj.locale);
      store.commit("language/SET_CURRENT_LANGUAGE", localeObj.locale);
    }
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "vi",
        img: require("@/assets/images/flags/vi.png"),
        name: "lbl_vietnamese"
      },
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "lbl_english"
      }
      // {
      //   locale: "fr",
      //   img: require("@/assets/images/flags/fr.png"),
      //   name: "French"
      // },
      // {
      //   locale: "de",
      //   img: require("@/assets/images/flags/de.png"),
      //   name: "German"
      // },
      // {
      //   locale: "pt",
      //   img: require("@/assets/images/flags/pt.png"),
      //   name: "Portuguese"
      // },
    ];
    /* eslint-disable global-require */

    return {
      locales
    };
  }
};
</script>

<style lang="scss"  scoped >
.hidden-dot {
  list-style-type: none;
}
</style>
