import { ROLES_SYSTEM } from "@/utils/constant"


export const tests =
  [
    {
      title: "lbl_required_test_manager",
      route: "orders",
      icon: "FileTextIcon",
      // role: [ROLES_SYSTEM.SUPPLIER],
      action: 'service-orders'
    },
    {
      title: "lbl_service_management",
      route: "services",
      icon: "PackageIcon",
      // role: [ROLES_SYSTEM.SUPPLIER],
      action: 'service-management',
      children: [
        {
          title: "lbl_product___services",
          route: "services",
          // icon: "ShoppingBagIcon",
          // role: [ROLES_SYSTEM.SUPPLIER],
          action: 'service-management'
        },
        {
          title: "lbl_inventory",
          route: "inventory-service",
          // icon: "DatabaseIcon",
          // role: [ROLES_SYSTEM.SUPPLIER],
          action: 'service-management'
        },
      ],
    },
    {
      title: "lbl_required_test_manager",
      route: "orders-processor",
      icon: "PackageIcon",
      // role: [ROLES_SYSTEM.PROCESSOR],
      action: 'processor-service-orders'
    },
  ]


export const tests_config =
  [
    {
      title: "lbl_delivery_definition_configs",
      route: "config-definition",
      // role: [ROLES_SYSTEM.SUPPLIER],
      action: 'configs'
    },
    {
      title: "lbl_delivery_status_configs",
      route: "config-delivery-status",
      // role: [ROLES_SYSTEM.SUPPLIER],
      action: 'configs'
    },
    {
      title: "lbl_required_attachment_configs",
      route: "config-order-form",
      // role: [ROLES_SYSTEM.SUPPLIER],
      action: 'configs'
    },
  ]

