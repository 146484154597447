import { ROLES_SYSTEM } from "@/utils/constant"

export const credit =
  [
    {
      title: "lbl_credit",
      route: "credit",
      icon: "TargetIcon",
      // role: [ROLES_SYSTEM.SUPPLIER],
      action: 'product-management',
      children: [
        {
          title: "Thiết lập tỷ lệ",
          route: "ConfigCredit",
          // role: [ROLES_SYSTEM.SUPPLIER],
          action: 'product-management'
        },
        {
          title: "Tích điểm",
          route: "accumulate",
          // role: [ROLES_SYSTEM.SUPPLIER],
          action: 'product-management'
        },
        {
          title: "Tiêu điểm",
          route: "spend",
          // role: [ROLES_SYSTEM.SUPPLIER],
          action: 'product-management'
        },
        {
          title: "Nạp điểm",
          route: "deposit",
          // role: [ROLES_SYSTEM.SUPPLIER],
          action: 'product-management'
        },
      ],
    },
  ]


export const ecom = [
  {
    title: "lbl_orders",
    route: "orders-market-place",
    icon: "ShoppingCartIcon",
    // role: [ROLES_SYSTEM.SUPPLIER],
    action: 'product-orders'
  },
  {
    title: "lbl_product_management",
    route: "products",
    icon: "ShoppingBagIcon",
    // role: [ROLES_SYSTEM.SUPPLIER],
    action: 'product-management',
    children: [
      {
        title: "lbl_products",
        route: "products",
        // icon: "ShoppingBagIcon",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'product-management'
      },
      {
        title: "lbl_inventory_management",
        route: "inventory-product",
        // icon: "DatabaseIcon",
        // role: [ROLES_SYSTEM.SUPPLIER_PRODUCT],
        action: 'product-management'
      },
      {
        title: "lbl_document_management",
        route: "bill-manager",
        // icon: "FileTextIcon",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'product-management',
        children: [
          {
            title: "lbl_import_receiving",
            route: "import",
            // role: [ROLES_SYSTEM.SUPPLIER],
            action: 'product-management'
          },
          {
            title: "lbl_export_receiving",
            route: "export",
            // role: [ROLES_SYSTEM.SUPPLIER],
            action: 'product-management'
          },
        ],
      },
    ],
  },
  // {
  //   title: "Phiếu thu",
  //   route: "indication",
  //   icon: "ClipboardIcon",
  //   role: [ROLES_SYSTEM.SUPPLIER],
  // },
]

export const ecom_config = [
  {
    title: "lbl_product_and_service_information",
    route: "config-product",
    // role: [ROLES_SYSTEM.SUPPLIER],
    action: 'configs',
    children: [
      {
        title: "Quy cách đóng gói",
        route: "config-specification",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'configs'
      },
      {
        title: "Dạng bào chế",
        route: "config-dosage-form",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'configs'
      },
      {
        title: "lbl_unit",
        route: "config-unit-manager",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'configs'
      },
      {
        title: "lbl_category",
        route: "config-category",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'configs'
      },
      {
        title: "Nhà sản xuất",
        route: "config-manufacture-manager",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'configs'
      },
      {
        title: "Nguồn gốc",
        route: "config-origin",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'configs'
      },
      {
        title: "lbl_provider",
        route: "config-provider-manager",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'configs'
      },
      {
        title: "lbl_brand.product",
        route: "config-brand-manager",
        // role: [ROLES_SYSTEM.SUPPLIER],
        action: 'configs'
      },
    ],
  },
]