import { credit, ecom, ecom_config } from "./ecom"
import { tests, tests_config } from "./tests"

import { ROLES_SYSTEM } from "@/utils/constant"

export default [
  {
    title: "lbl_home",
    route: "home",
    icon: "HomeIcon",
    // role: [ROLES_SYSTEM.ALL],
    action: 'common'
  },
  ...tests,
  {
    title: "lbl_return_result",
    route: "processor_result",
    icon: "FilePlusIcon",
    // role: [ROLES_SYSTEM.PROCESSOR],
    action: 'processor-service-result'
  },
  {
    title: "lbl_return_result",
    route: "supplier_result",
    icon: "FilePlusIcon",
    // role: [ROLES_SYSTEM.SUPPLIER],
    action: 'service-result'
  },
  ...ecom,
  ...credit,
  {
    title: "lbl_reports",
    route: "reports",
    icon: "BarChart2Icon",
    // role: [ROLES_SYSTEM.ALL],
    action: 'report'
  },
  {
    title: "lbl_config_settings",
    route: "configs",
    icon: "SettingsIcon",
    // role: [ROLES_SYSTEM.SUPPLIER],
    children: [...tests_config, ...ecom_config],
    action: 'configs'
  },

]

export const PARTNER_PRODUCT_MENU = [
  {
    title: "lbl_home",
    route: "home",
    icon: "HomeIcon",
    // role: [ROLES_SYSTEM.ALL],
    action: 'common'
  },
  ...tests,
  {
    title: "lbl_return_result",
    route: "supplier_result",
    icon: "FilePlusIcon",
    // role: [ROLES_SYSTEM.SUPPLIER],
    action: 'service-result'
  },
  ...ecom,
  ...credit,
  {
    title: "lbl_reports",
    route: "reports",
    icon: "BarChart2Icon",
    // role: [ROLES_SYSTEM.ALL],
    action: 'report'
  },
  {
    title: "lbl_config_settings",
    route: "configs",
    icon: "SettingsIcon",
    // role: [ROLES_SYSTEM.SUPPLIER],
    children: [...tests_config, ...ecom_config],
    action: 'configs'
  },
]